import { shallowReactive } from 'vue'
import { ErrorService } from '../diagnostics/error-service'
import { promiseDebounce } from '../utils/object'

export function useAPIHandle<T, Params extends any[]>(promise: (...args: Params) => Promise<T>, initialData = {} as T) {
  const DEFAULT_STATE = {
    result: initialData,

    loading: false,
    loaded: false,
    error: false
  }

  const state = shallowReactive(DEFAULT_STATE)

  const start = promiseDebounce(async (...args: Params) => {
    state.loading = true

    try {
      state.result = await promise(...args)
    }
    catch (error) {
      state.error = true
      ErrorService.log(error)
    }

    state.loading = false
    state.loaded = true

    return state.result
  })

  function reset() {
    Object.assign(state, DEFAULT_STATE)
    state.result = initialData
  }

  return [state, start, reset] as const
}
